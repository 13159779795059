import React, { useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../.../../../store/index';
import { useStyles } from './FilterDrawer.style';
import { useSelector } from 'react-redux';
import * as actions from '../../../store/index';
import InstitutionNameRules from './FilterDrawerOptions/FilterDrawerDevices/InstitutionNameRules/InstitutionNameRules';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/core/styles';
import { unwindDevices } from '../../../helpers/misc';
import {
  Drawer,
  Button,
  ListItem,
  ListItemText,
  FormControlLabel,
  Checkbox,
  IconButton,
  Tooltip,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import { filterOptionsLabels, filterOptionsTitle } from './FilterDrawer.utils';
import FiltersOptions from './FilterDrawerOptions/FiltersOptions';
import FilterDevicesOptions from './FilterDrawerOptions/FilterDrawerDevices/FilterDevicesOptions';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  DIAGNOSTICS_MICROCALC,
  DIAGNOSTICS_OPACITIES,
  PREMIUM_CANCER_RISK,
  PREMIUM_DENSITY,
} from '../../../config';
import { filtersInitialData } from './filters.constants';
import { fetchDelete, fetchGet } from '../../../helpers/fetching';
import CustomFilter from './CustomFilter/CustomFilter';
import {
  Autorenew,
  DoneAll,
  CheckBoxOutlineBlank,
  CheckBoxRounded,
} from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';

export default function FilterDrawer() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();

  const filter = useSelector((state) => state.filter);
  const { operator_list, device_list } = useSelector(
    (state) => state.operators_devices
  );
  const activeDialog = useSelector((state) => state.gui.activeDialog);

  const [instRulesOpen, setInstRulesOpen] = useState(false);
  const [customFilterOpen, setCustomFilterOpen] = useState(false);
  const [localFilter, setLocalFilter] = useState({});
  const [customFilterList, setCustomFilterList] = useState([]);
  const [selectedCustomName, setSelectedCustomName] = useState('');

  const unwound_devices = unwindDevices(device_list);
  const { multiCaptures, multiExams, ...restDrawerFilters } =
    filtersInitialData;

  /* DIAGNOSTICS FEATURE HIDDEN FROM .ENV*/
  if (localStorage.getItem('diagnostics') === 'true') {
    delete restDrawerFilters.opacities;
    delete restDrawerFilters.microcalc;
  }
  if (!DIAGNOSTICS_MICROCALC) {
    delete restDrawerFilters.microcalc;
  }
  if (!DIAGNOSTICS_OPACITIES) {
    delete restDrawerFilters.opacities;
  }

  if (!PREMIUM_DENSITY) {
    delete restDrawerFilters.density;
  }
  /* RISK FEATURE HIDDEN FROM .ENV*/
  if (!PREMIUM_CANCER_RISK) {
    delete restDrawerFilters.risk;
  }

  const miscellaneous = {
    multiCaptures,
    multiExams,
  };

  const applyFilters = () => {
    if (selectedCustomName) {
      getFilterDetails();
    }
    dispatch(actions.setFilter(localFilter));
    dispatch(actions.setActiveDialog(null));
  };

  const getWholeList = (listName) => {
    switch (listName) {
      case 'devices':
        return unwound_devices;
      case 'operators':
        return operator_list.map((operator) => {
          if (operator.aliases) return operator.aliases.join(';');
          return operator.name;
        });
      default:
        if (listName in restDrawerFilters) return restDrawerFilters[listName];
        if (listName in miscellaneous) return miscellaneous[listName];
        return [];
    }
  };

  const getSelectedList = (listName) => {
    if (listName in localFilter) {
      return localFilter[listName];
    }
    return getWholeList(listName);
  };

  const selectAllHandler = (filterTitle) => {
    const newLocalFilter = { ...localFilter };
    if (filterTitle in localFilter) {
      delete newLocalFilter[filterTitle];
    } else {
      newLocalFilter[filterTitle] = [];
    }

    setLocalFilter(newLocalFilter);
  };

  const onCheckboxChangeHandler = (filterTitle, checkboxValue, checked) => {
    let newLocalFilter = { ...localFilter };

    if (checked) {
      if (filterTitle in localFilter) {
        newLocalFilter[filterTitle] = [
          ...newLocalFilter[filterTitle],
          checkboxValue,
        ];
      } else {
        newLocalFilter[filterTitle] = [checkboxValue];
      }
    } else {
      if (filterTitle in localFilter) {
        newLocalFilter[filterTitle] = newLocalFilter[filterTitle].filter(
          (item) => item !== checkboxValue
        );
      } else {
        newLocalFilter[filterTitle] = getWholeList(filterTitle).filter(
          (item) => item !== checkboxValue
        );
      }
    }

    if (
      newLocalFilter[filterTitle].length === getWholeList(filterTitle).length
    ) {
      delete newLocalFilter[filterTitle];
    }

    setLocalFilter(newLocalFilter);
  };

  const onMiscChange = (filterTitle, checked) => {
    let newLocalFilter = { ...localFilter };
    if (checked) {
      newLocalFilter[filterTitle] = true;
    } else {
      delete newLocalFilter[filterTitle];
    }

    setLocalFilter(newLocalFilter);
  };

  function institutionHandler(event) {
    const { checked, name } = event.target;
    let newDeviceList;
    const wholeList = getWholeList('devices');

    if (checked) {
      if ('devices' in localFilter) {
        newDeviceList = localFilter.devices.concat(device_list[name]);
      } else {
        newDeviceList = device_list[name];
      }
    } else {
      if ('devices' in localFilter) {
        newDeviceList = localFilter.devices.filter(
          (item) => !device_list[name].includes(item)
        );
      } else {
        newDeviceList = wholeList.filter(
          (item) => !device_list[name].includes(item)
        );
      }
    }

    if (newDeviceList.length === wholeList.length) {
      setLocalFilter((prevState) => {
        delete prevState['devices'];
        return prevState;
      });
    } else {
      setLocalFilter((prevState) => {
        return {
          ...prevState,
          devices: newDeviceList,
        };
      });
    }
  }

  /* CUSTOM FILTER */
  async function getFilterList() {
    const response = await fetchGet('patient_filter_list');
    if (response.success) {
      let newList = response.data;
      setCustomFilterList(newList);
    } else {
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
    }
  }

  // Get selected filter details
  const getFilterDetails = async () => {
    if (!selectedCustomName || selectedCustomName === '') return;

    try {
      const response = await fetchGet('patient_filter/' + selectedCustomName);
      if (response.success && response.data) {
        const filteredIds = response.data
          .filter((study) => study.found)
          .map((study) => study.studyId);

        const studyList = {
          study_list: filteredIds,
          study_name: selectedCustomName,
        };
        const allLocalFilters = { ...localFilter, ...studyList };
        dispatch(actions.setFilter(allLocalFilters));

        dispatch(
          setSnackbar({
            msg:
              studyList?.study_list.length <= 0 || !studyList.study_list
                ? t('Filters.no_studies_found')
                : t('Filters.filter_applied'),
            severity:
              studyList?.study_list.length <= 0 || !studyList.study_list
                ? 'warning'
                : 'success',
          })
        );
      } else {
        dispatch(
          setSnackbar({
            msg: t('Filters.nothing_found'),
            severity: 'error',
          })
        );
      }
    } catch (error) {
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
    }
  };
  const customFilterRadioChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCustomName(
      selectedValue === selectedCustomName ? '' : selectedValue
    );

    if (selectedValue === selectedCustomName) {
      const newFilter = { ...localFilter };
      delete newFilter['study_name'];
      delete newFilter['study_list'];
      setLocalFilter(newFilter);
    } else {
      const newFilter = { ...localFilter, study_name: selectedValue };
      setLocalFilter(newFilter);
    }
  };

  const deleteCustomFilter = async (filterName) => {
    const response = await fetchDelete('patient_filter', { name: filterName });

    if (response.success) {
      dispatch(
        setSnackbar({
          msg: t('Filters.filter_removed'),
          severity: 'info',
        })
      );
      getFilterList();
    } else {
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
    }

    if (filterName === selectedCustomName) {
      const newFilter = { ...localFilter };
      delete newFilter['study_name'];
      delete newFilter['study_list'];
      setLocalFilter(newFilter);
      dispatch(actions.setFilter(newFilter));
    }
  };

  const resetFilters = () => {
    setLocalFilter({});
    setSelectedCustomName('');
    dispatch(actions.resetFilter());
  };

  // Fetch filters
  useEffect(() => {
    getFilterList();
    if (filter.study_name) {
      setSelectedCustomName(filter.study_name);
    }
  }, []);

  // Sync local filter with global filter state
  useEffect(() => {
    setLocalFilter(filter);
  }, [filter]);

  return (
    <>
      <div className={classes.filterDrawerContainer}>
        <Drawer
          style={{ zIndex: theme.zIndex.backdrops_drawer }}
          data-test-id="filter-drawer"
          open={activeDialog === 'filter'}
          anchor={'right'}
          transitionDuration={600}
          BackdropProps={{
            style: {
              backgroundColor: 'black',
              opacity: 0.7,
            },
          }}
          onClose={(_e, reason) => {
            if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
              dispatch(actions.setActiveDialog(null));
            }
            dispatch(actions.setActiveDialog(null));
          }}
        >
          <div className={classes.drawerWidthContainer}>
            <div className={classes.filterHeader}>
              <Tooltip
                arrow
                placement="right"
                title={
                  <Typography
                    style={{
                      letterSpacing: '0.1rem',
                      textTransform: 'uppercase',
                      fontSize: '12px',
                    }}
                    color="inherit"
                  >
                    {t('Console.close')}
                  </Typography>
                }
                aria-label="add"
              >
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  onClick={() => dispatch(actions.setActiveDialog(null))}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>

              <div className={classes.filterDrawer_MainButtons}>
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => resetFilters()}
                  className={classes.filterButtonsDetail}
                >
                  {t('Filters.reset')}
                  <Autorenew fontSize="small" />
                </Button>

                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={applyFilters}
                  className={classes.filterButtonsDetail}
                >
                  {t('Filters.apply')}
                  <DoneAll fontSize="small" />
                </Button>
              </div>
            </div>

            <div className={classes.optionsContainer}>
              <div className={classes.filterOptions}>
                {/*---------- Custom Filter Lists --------*/}
                <Accordion>
                  <AccordionSummary
                    style={{ padding: '0px 16px 0px 0px' }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className={classes.listDevicesTitleContainer}>
                      <ListItem>
                        <ListItemText
                          primary={t('Filters.custom_filter')}
                          primaryTypographyProps={{
                            variant: 'body2',
                            fontWeight: 'medium',
                            fontSize: theme.fonts.responsiveSmall,
                          }}
                          className={classes.customFilterText}
                        />
                      </ListItem>
                      <div className={classes.devicesButtons}>
                        <Button
                          data-test-id="custom-filter"
                          className={classes.addRuleButton}
                          startIcon={<AddIcon />}
                          variant="outlined"
                          size="small"
                          onClick={(e) => {
                            e.preventDefault(),
                              e.stopPropagation(),
                              setCustomFilterOpen(true);
                          }}
                        >
                          {t('Filters.add_custom_filter')}
                        </Button>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {customFilterList.length > 0 ? (
                      <FormControl
                        component="fieldset"
                        style={{ width: '100%' }}
                      >
                        <RadioGroup
                          className={classes.studyList}
                          value={selectedCustomName}
                        >
                          {customFilterList.map((filter, index) => (
                            <div
                              key={index}
                              className={classes.radio_list_container}
                            >
                              <FormControlLabel
                                style={{
                                  marginRight: '1px',
                                  textOverflow: 'ellipsis',
                                  maxWidth: '90%',
                                  overflow: 'hidden',
                                }}
                                value={filter.name}
                                control={
                                  <Radio
                                    size="small"
                                    onClick={customFilterRadioChange}
                                  />
                                }
                                label={filter.name}
                              />
                              <Tooltip
                                title={
                                  <Typography className={classes.tooltip_text}>
                                    {t('Filters.delete')}
                                  </Typography>
                                }
                                arrow
                                placement="top"
                              >
                                <IconButton
                                  aria-label="delete"
                                  onClick={() =>
                                    deleteCustomFilter(filter.name)
                                  }
                                  size="small"
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </div>
                          ))}
                        </RadioGroup>
                      </FormControl>
                    ) : (
                      <Typography className={classes.nothing_found}>
                        {t('Filters.no_custom')}
                      </Typography>
                    )}
                  </AccordionDetails>
                </Accordion>

                {/*---------- Devices / Institutions --------*/}
                <Accordion>
                  <AccordionSummary
                    style={{ padding: '0px 16px 0px 0px' }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className={classes.listDevicesTitleContainer}>
                      <ListItem>
                        <ListItemText
                          primary={t('Filters.devices')}
                          primaryTypographyProps={{
                            variant: 'body2',
                            fontWeight: 'medium',
                            fontSize: theme.fonts.responsiveSmall,
                          }}
                          className={classes.listDevicesText}
                        />
                      </ListItem>
                      <div className={classes.devicesButtons}>
                        <Button
                          data-test-id="devices-add-rule"
                          className={classes.addRuleButton}
                          startIcon={<AddIcon />}
                          variant="outlined"
                          disabled={
                            localStorage.getItem('diagnostics') === 'true'
                          }
                          size="small"
                          onClick={(e) => {
                            e.preventDefault(),
                              e.stopPropagation(),
                              setInstRulesOpen(true);
                          }}
                        >
                          {t('Filters.addRule')}
                        </Button>
                        <Button
                          className={classes.selectButton_accordion}
                          startIcon={
                            'devices' in localFilter ? (
                              <CheckBoxOutlineBlank />
                            ) : (
                              <CheckBoxRounded />
                            )
                          }
                          size="small"
                          onClick={(e) => {
                            e.preventDefault(),
                              e.stopPropagation(),
                              selectAllHandler('devices');
                          }}
                        >
                          {'devices' in localFilter
                            ? t('Filters.selectAll')
                            : t('Filters.unselectAll')}
                        </Button>
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>
                      <FilterDevicesOptions
                        direction="column"
                        title={'devices'}
                        items={device_list}
                        items_len={unwound_devices?.length}
                        selected={getSelectedList('devices')}
                        onChange={onCheckboxChangeHandler}
                        onInstChange={institutionHandler}
                        setInstRulesOpen={setInstRulesOpen}
                      />
                    </>
                  </AccordionDetails>
                </Accordion>

                {/* ---------- Operators ----------- */}
                {localStorage.getItem('role') !== 'technician' && (
                  <Accordion>
                    <AccordionSummary
                      style={{ padding: '0px 16px 0px 0px' }}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <div className={classes.listOperatorTitleContainer}>
                        <ListItem>
                          <ListItemText
                            className={classes.listItemText}
                            primary={t('Filters.operators')}
                            primaryTypographyProps={{
                              variant: 'body2',
                              fontWeight: 'medium',
                            }}
                          />
                          <Button
                            className={classes.selectButton_accordion}
                            startIcon={
                              'operators' in localFilter ? (
                                <CheckBoxOutlineBlank />
                              ) : (
                                <CheckBoxRounded />
                              )
                            }
                            size="small"
                            onClick={(e) => {
                              e.preventDefault(),
                                e.stopPropagation(),
                                selectAllHandler('operators');
                            }}
                          >
                            {'operators' in localFilter
                              ? t('Filters.selectAll')
                              : t('Filters.unselectAll')}
                          </Button>
                        </ListItem>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <>
                        <FiltersOptions
                          type={typeof operator_list}
                          title={'operators'}
                          labels={operator_list}
                          items={operator_list}
                          selected={getSelectedList('operators')}
                          onChange={onCheckboxChangeHandler}
                        />
                      </>
                    </AccordionDetails>
                  </Accordion>
                )}

                {/* ---------- Other Filters --------------*/}
                {Object.entries(restDrawerFilters).map(
                  ([filterTitle, filterValues], i) => {
                    return (
                      <Fragment key={filterTitle + i}>
                        <div className={classes.listItemTitleContainer}>
                          <ListItem>
                            <ListItemText
                              className={classes.listItemText}
                              primary={filterOptionsTitle(filterTitle)}
                              primaryTypographyProps={{
                                variant: 'body2',
                                fontWeight: 'medium',
                              }}
                            />

                            <Button
                              className={classes.selectButton}
                              startIcon={
                                filterTitle in localFilter ? (
                                  <CheckBoxOutlineBlank />
                                ) : (
                                  <CheckBoxRounded />
                                )
                              }
                              size="small"
                              onClick={() => selectAllHandler(filterTitle)}
                            >
                              {filterTitle in localFilter
                                ? t('Filters.unselectAll')
                                : t('Filters.selectAll')}
                            </Button>
                          </ListItem>
                        </div>
                        <FiltersOptions
                          type={typeof filterValues}
                          title={filterTitle}
                          labels={filterOptionsLabels(filterTitle)}
                          items={filterValues}
                          id={i}
                          selected={getSelectedList(filterTitle)}
                          onChange={onCheckboxChangeHandler}
                        />
                      </Fragment>
                    );
                  }
                )}

                {/* ---------- Miscellaneous --------------*/}
                <>
                  <div className={classes.listItemTitleContainer}>
                    <ListItem>
                      <ListItemText
                        className={classes.listItemText}
                        primary={t('Settings.misc')}
                        primaryTypographyProps={{
                          variant: 'body2',
                          fontWeight: 'medium',
                        }}
                      />
                    </ListItem>
                  </div>
                  <div className={classes.optionsGridContainer}>
                    {Object.entries(miscellaneous).map(([filterTitle, id]) => {
                      return (
                        <FormControlLabel
                          key={filterTitle + id}
                          control={<Checkbox name={filterTitle} />}
                          label={filterOptionsLabels(filterTitle)}
                          onChange={(e) =>
                            onMiscChange(filterTitle, e.target.checked)
                          }
                          checked={filterTitle in localFilter}
                        />
                      );
                    })}
                  </div>
                </>
              </div>
            </div>
          </div>
        </Drawer>
      </div>

      {instRulesOpen && (
        <InstitutionNameRules
          open={instRulesOpen}
          onClose={() => setInstRulesOpen(false)}
          institutions={Object.keys(device_list)}
        />
      )}

      {customFilterOpen && (
        <CustomFilter
          open={customFilterOpen}
          onClose={() => setCustomFilterOpen(false)}
        />
      )}
    </>
  );
}
