import { createSlice } from '@reduxjs/toolkit';

import {
  fetchGet,
  fetchPost,
  fetchPut,
  fetchDelete,
} from '../../helpers/fetching';
import {
  setSnackbar,
  setResults,
  setExaminationList,
  fetchResults,
  setActionToConfirm,
  sendReports,
  setConfirmed,
  setApplied,
} from '../index';

import { QUALITY_VALUES } from '../../config';
import {
  addDerivedFeaturesCC,
  addDerivedFeaturesMLO,
  addPNLEval,
  addSymmetryEval,
} from '../../helpers/derivedQualityFeatures';

const initialState = {
  qualityInRevisionMode: false,
  qualityInDrawingMode: false,
  densityInRevisionMode: false,
  diagnosticsInRevisionMode: false,

  shownQualityOverlayType: '',
  localChangesDensity: false,
  localChangesQuality: false,
  localChangesOpacities: false,
  localChangesMicrocalc: false,

  qualityEvalChanges: false,

  customDensity: {
    rcc: null,
    lcc: null,
    rmlo: null,
    lmlo: null,
  },

  customQuality: {
    rcc: null,
    lcc: null,
    rmlo: null,
    lmlo: null,
  },

  customOpacities: {
    rcc: null,
    lcc: null,
    rmlo: null,
    lmlo: null,
  },

  customMicrocalc: {
    rcc: null,
    lcc: null,
    rmlo: null,
    lmlo: null,
  },

  customQualityOverlays: {},

  drawing: {
    radius: 30,
    mode: '',
  },

  noTypeRectangles: {
    rcc: [],
    lcc: [],
    rmlo: [],
    lmlo: [],
  },

  groupedOpacities: {
    RightBreast: [],
    LeftBreast: [],
  },

  lesion_index_mapping: {},

  singleBoxHover: null,
  savingPending: false,
};

export const labelingSlice = createSlice({
  name: 'labeling',
  initialState,
  reducers: {
    setQualityInRevisionMode: (state, action) => {
      state.qualityInRevisionMode = action.payload;
    },
    setQualityInDrawingMode: (state, action) => {
      state.qualityInDrawingMode = action.payload;
    },
    setDensityInRevisionMode: (state, action) => {
      state.densityInRevisionMode = action.payload;
    },
    setDiagnosticsInRevisionMode: (state, action) => {
      state.diagnosticsInRevisionMode = action.payload;
    },
    setCustomDensity: (state, action) => {
      state.customDensity = action.payload;
    },
    setCustomQuality: (state, action) => {
      state.customQuality = action.payload;
    },
    setCustomMicrocalc: (state, action) => {
      state.customMicrocalc = action.payload;
    },
    setCustomOpacities: (state, action) => {
      state.customOpacities = action.payload;
    },
    setDrawing: (state, action) => {
      state.drawing = action.payload;
    },
    setInitialLabeling: (state, action) => {
      const newState = { ...initialState };
      if (action.payload) {
        for (let exception of action.payload) {
          if (exception in state) newState[exception] = state[exception];
        }
      }
      return newState;
    },

    setShownQualityOverlayType: (state, actions) => {
      state.shownQualityOverlayType = actions.payload;
    },

    setQualityLocalChanges: (state, actions) => {
      state.localChangesQuality = actions.payload;
    },

    setMicrocalcLocalChanges: (state, actions) => {
      state.localChangesMicrocalc = actions.payload;
    },
    setOpacitiesLocalChanges: (state, actions) => {
      state.localChangesOpacities = actions.payload;
    },
    setDensityLocalChanges: (state, actions) => {
      state.localChangesDensity = actions.payload;
    },

    setQualityEvalChanged: (state, actions) => {
      state.qualityEvalChanges = actions.payload;
    },
    setSavingPending: (state, actions) => {
      state.savingPending = actions.payload;
    },
    setCustomQualityOverlays: (state, actions) => {
      state.customQualityOverlays = actions.payload;
    },
    setDiscardedLabeling: (state) => {
      return { ...initialState };
    },

    setSingleBoxHover: (state, actions) => {
      state.singleBoxHover = actions.payload;
    },

    setInitialRectangles: (state) => {
      state.noTypeRectangles = initialState.noTypeRectangles;
    },

    setNoTypeRectangles: (state, actions) => {
      state.noTypeRectangles = actions.payload;
    },

    setGroupedOpacities: (state, actions) => {
      state.groupedOpacities = actions.payload;
    },

    setLesionIndexMapping: (state, actions) => {
      state.lesion_index_mapping = actions.payload;
    },
  },
});

export const {
  setCustomDensity,
  setCustomQuality,
  setCustomOpacities,
  setCustomMicrocalc,
  setCustomQualityOverlays,

  setQualityInRevisionMode,
  setQualityInDrawingMode,
  setDensityInRevisionMode,
  setDiagnosticsInRevisionMode,

  setDrawing,

  setInitialLabeling,

  setShownQualityOverlayType,

  setQualityLocalChanges,
  setMicrocalcLocalChanges,
  setOpacitiesLocalChanges,
  setDensityLocalChanges,
  setQualityEvalChanged,
  setSavingPending,

  setSingleBoxHover,

  setNoTypeRectangles,

  setGroupedOpacities,
  setLesionIndexMapping,

  setInitialRectangles,
} = labelingSlice.actions;

export default labelingSlice.reducer;

// Derived
export const updateCustomDensity = (projection, evaluation) => {
  return (dispatch, getState) => {
    const newCustomDensity = {
      ...getState().customDensity,
      [projection]: evaluation,
    };
    dispatch(setCustomDensity(newCustomDensity));
  };
};

export const updateCustomQuality = (projection, evaluation) => {
  return (dispatch, getState) => {
    const newCustomQuality = {
      ...getState().labeling.customQuality,
      [projection.toLowerCase()]: evaluation,
    };
    dispatch(setCustomQuality(newCustomQuality));
  };
};

export const updateCustomOpacity = (projection, evaluation) => {
  return (dispatch, getState) => {
    const newCustomOpacity = {
      ...getState().customOpacity,
      [projection]: evaluation,
    };
    dispatch(setCustomOpacities(newCustomOpacity));
  };
};

export const updateCustomMicrocalc = (projection, evaluation) => {
  return (dispatch, getState) => {
    const newCustomMicrocalc = {
      ...getState().customMicrocalc,
      [projection]: evaluation,
    };
    dispatch(setCustomMicrocalc(newCustomMicrocalc));
  };
};

export const updateCustomQualityWithDerrived = (
  projection,
  newResult,
  field
) => {
  return (dispatch, getState) => {
    const projections = getState().results.projections;
    const PNL_DIFF = getState().gui.PNL_DIFF;
    const PNL_FROM_EDGE = getState().gui.PNL_FROM_EDGE;
    const NIPPLE_ANGLE = getState().gui.NIPPLE_ANGLE;
    const SYMMETRY = getState().gui.SYMMETRY;

    if (projection === 'RCC') {
      const resultMLO = { ...getState().labeling.customQuality.rmlo };
      const resultLCC = { ...getState().labeling.customQuality.lcc };
      addDerivedFeaturesCC(
        newResult,
        projections.rcc,
        true,
        NIPPLE_ANGLE,
        field
      );
      addPNLEval(newResult, resultMLO, PNL_DIFF);
      // addSymmetryEval(resultLCC, newResult, SYMMETRY);
      dispatch(updateCustomQuality('RCC', newResult));
      dispatch(updateCustomQuality('LCC', resultLCC));
    } else if (projection === 'LCC') {
      const resultMLO = { ...getState().labeling.customQuality.lmlo };
      const resultRCC = { ...getState().labeling.customQuality.rcc };
      addDerivedFeaturesCC(
        newResult,
        projections.lcc,
        false,
        NIPPLE_ANGLE,
        field
      );
      addPNLEval(newResult, resultMLO, PNL_DIFF);
      // addSymmetryEval(newResult, resultRCC, SYMMETRY);
      dispatch(updateCustomQuality('LCC', newResult));
      dispatch(updateCustomQuality('RCC', resultRCC));
    } else if (projection === 'RMLO') {
      const resultCC = { ...getState().labeling.customQuality.rcc };
      const resultLMLO = { ...getState().labeling.customQuality.lmlo };
      addDerivedFeaturesMLO(newResult, projections.rmlo, true, PNL_FROM_EDGE);
      addPNLEval(resultCC, newResult, PNL_DIFF);
      addSymmetryEval(resultLMLO, newResult, SYMMETRY);
      dispatch(updateCustomQuality('RMLO', newResult));
      dispatch(updateCustomQuality('RCC', resultCC));
      dispatch(updateCustomQuality('LMLO', resultLMLO));
    } else if (projection === 'LMLO') {
      const resultCC = { ...getState().labeling.customQuality.lcc };
      const resultRMLO = { ...getState().labeling.customQuality.rmlo };
      addDerivedFeaturesMLO(newResult, projections.lmlo, false, PNL_FROM_EDGE);
      addPNLEval(resultCC, newResult, PNL_DIFF);
      addSymmetryEval(newResult, resultRMLO, SYMMETRY);
      dispatch(updateCustomQuality('LMLO', newResult));
      dispatch(updateCustomQuality('LCC', resultCC));
      dispatch(updateCustomQuality('RMLO', resultRMLO));
    }
  };
};

export const updateCustomQualityFeature = (
  projection,
  field,
  newValue,
  withDerrived = false
) => {
  return (dispatch, getState) => {
    const customQuality =
      getState().labeling?.customQuality?.[projection.toLowerCase()];
    const newCustomQuality = {
      ...customQuality,
      [field]: newValue,
    };

    if (withDerrived) {
      dispatch(
        updateCustomQualityWithDerrived(projection, newCustomQuality, field)
      );
    } else {
      dispatch(updateCustomQuality(projection, newCustomQuality));
    }
    dispatch(setQualityLocalChanges(true));
  };
};

export const switchQuality = (
  projection,
  field,
  base,
  withDerrived = false
) => {
  return (dispatch, getState) => {
    const customQuality =
      getState().labeling.customQuality[projection.toLowerCase()];
    const projObject = getState().results.projections[projection.toLowerCase()];

    if (!projObject) return;

    const quality = ['insufficient', 'correct', 'moderate'];
    let idx = quality?.indexOf(customQuality[field]);
    idx = (idx + 1) % base;

    dispatch(
      updateCustomQualityFeature(projection, field, quality[idx], withDerrived)
    );
  };
};

export const switchNippleTiltQuality = (
  projection,
  field,
  base,
  withDerived = false
) => {
  return (dispatch, getState) => {
    const customQuality =
      getState().labeling.customQuality[projection.toLowerCase()];
    const nippleAngle =
      getState().labeling.customQuality[projection.toLowerCase()]?.NippleAngle;

    if (!customQuality) return;

    const quality = ['correct', 'insufficient', 'moderate'];

    let idx = quality.indexOf(customQuality[field]);
    idx = (idx + 1) % base;

    if (idx === 0) {
      const newNippleAngle =
        nippleAngle < 0 ? Math.abs(nippleAngle) : -nippleAngle;
      dispatch(
        updateCustomQualityFeature(projection, 'NippleAngle', newNippleAngle)
      );
    }

    dispatch(
      updateCustomQualityFeature(projection, field, quality[idx], withDerived)
    );
  };
};

export const switchPectShapeQuality = (
  projection,
  field,
  base,
  withDerrived = false
) => {
  return (dispatch, getState) => {
    const customQuality =
      getState().labeling.customQuality[projection.toLowerCase()];
    const projObject = getState().results.projections[projection.toLowerCase()];

    if (!projObject) return;

    const quality = ['convex', 'straight', 'concave'];
    let idx = quality.indexOf(customQuality[field]);
    idx = (idx + 1) % base;

    dispatch(
      updateCustomQualityFeature(projection, field, quality[idx], withDerrived)
    );
  };
};

export const switchQualityEvaluation = (projection, t) => {
  return (dispatch, getState) => {
    const customQuality =
      getState().labeling.customQuality[projection.toLowerCase()];

    const quality = QUALITY_VALUES;

    if (quality) {
      let idx = quality?.indexOf(customQuality.quality);
      idx = (idx + 1) % quality.length;
      dispatch(setQualityEvalChanged(true));
      dispatch(updateCustomQualityFeature(projection, 'quality', quality[idx]));
      dispatch(
        updateCustomQualityFeature(projection, 'quality_explanation', [
          'Reviewed by a user',
        ])
      );
    } else
      dispatch(
        setSnackbar({
          msg: t('Confirm.contact_brayz'),
          severity: 'info',
        })
      );
  };
};

export const switchBuckyHeightQuality =
  (projection) => (dispatch, getState) => {
    const customQuality =
      getState().labeling.customQuality[projection.toLowerCase()];
    const projObject = getState().results.projections[projection.toLowerCase()];

    if (!projObject) return;

    const quality = ['low', 'correct', 'high'];
    const currentQuality = customQuality['elevation'];
    const currentIndex = quality?.indexOf(currentQuality);
    const nextIndex = (currentIndex + 1) % quality.length;
    const nextQuality = quality[nextIndex];

    dispatch(updateCustomQualityFeature(projection, 'elevation', nextQuality));
  };

export const updateDensity = (projection, newClass, t) => {
  projection = projection.toLowerCase();

  return (dispatch, getState) => {
    const proj = getState().results.projections[projection.toLowerCase()];
    if (!proj) return;

    const newProba = [0, 0, 0, 0];
    const densities = ['A', 'B', 'C', 'D'];
    const idx = densities.indexOf(newClass);
    if (idx < 0) {
      dispatch(
        setSnackbar({
          msg: t('Confirm.density_annotation_failed'),
          severity: 'error',
        })
      );
      return;
    }
    newProba[idx] = 100;

    const newCustomDensity = { ...getState().labeling.customDensity };

    if (!(projection in newCustomDensity)) {
      dispatch(
        setSnackbar({
          msg: t('Confirm.density_annotation_failed_proj'),
          severity: 'error',
        })
      );
      return;
    }

    newCustomDensity[projection] = {
      ...newCustomDensity[projection],
      DensClass: newClass,
      DensProba: newProba,
    };

    dispatch(setCustomDensity(newCustomDensity));
    dispatch(setDensityLocalChanges(true));
  };
};

export const setEvaluation = (username, newEval, confirm = true) => {
  return (dispatch, getState) => {
    const results = getState().results;
    const newResults = {
      ...results,
      evaluations: {
        ...results.evaluations,
        [username]: newEval,
      },
    };
    if (confirm) {
      newResults.confirmed_by = null;
      newResults.revised_by = username;
    }
    dispatch(setResults(newResults));
  };
};

const setEvaluations = (newEvaluations, newRevisedBy) => {
  return (dispatch, getState) => {
    const results = getState().results;
    const newResults = {
      ...results,
      evaluations: newEvaluations,
      revised_by: newRevisedBy,
    };
    dispatch(setResults(newResults));
  };
};

export const updateCustomQualityOverlays = (
  overlayType,
  proj,
  imageDataUrl
) => {
  return (dispatch, getState) => {
    const customOverlays = getState().labeling.customQualityOverlays;

    const newCustomOverlays = {
      ...customOverlays,
      [proj]: {
        ...customOverlays[proj],
        [overlayType]: imageDataUrl,
      },
    };
    dispatch(setCustomQualityOverlays(newCustomOverlays));
  };
};

export const updateCustomDiagnostics = (
  removeMicrocalc,
  removeOpacities,
  proj
) => {
  return (dispatch, getState) => {
    const customMicrocalc = getState().labeling.customMicrocalc;
    const customOpacities = getState().labeling.customOpacities;

    if (removeMicrocalc || removeOpacities) {
      let newCustomMicrocalc = customMicrocalc;
      let newCustomOpacities = customOpacities;

      if (removeMicrocalc) {
        newCustomMicrocalc = {
          ...newCustomMicrocalc,
          [proj]: {
            ...newCustomMicrocalc[proj],
            benign: null,
            malignant: null,
          },
        };
      }

      if (removeOpacities) {
        newCustomOpacities = {
          ...newCustomOpacities,
          [proj]: {
            ...newCustomOpacities[proj],
            birads2: null,
            birads3: null,
            birads4: null,
            birads5: null,
          },
        };
      }

      dispatch(setCustomMicrocalc(newCustomMicrocalc));
      dispatch(setCustomOpacities(newCustomOpacities));
    }
  };
};

export const customResults = (module, appliedResults, getState) => {
  const results = getState().results;

  const customEvaluationResults = {
    rcc: results?.evaluations?.[module]?.[appliedResults]?.rcc,
    lcc: results?.evaluations?.[module]?.[appliedResults]?.lcc,
    rmlo: results?.evaluations?.[module]?.[appliedResults]?.rmlo,
    lmlo: results?.evaluations?.[module]?.[appliedResults]?.lmlo,
  };

  return customEvaluationResults;
};

export const setCustomResults = (results) => {
  return (dispatch, getState) => {
    const appliedDensity =
      getState().results.shown_density || getState().results.applied_density;
    const appliedQuality =
      getState().results.shown_quality || getState().results.applied_quality;
    const appliedMicrocalc =
      getState().results.shown_microcalc ||
      getState().results.applied_microcalc;
    const appliedOpacities =
      getState().results.shown_opacities ||
      getState().results.applied_opacities;

    const customDensity = customResults('density', appliedDensity, getState);
    const customQuality = customResults('quality', appliedQuality, getState);
    const customMicrocalc = customResults(
      'microcalc',
      appliedMicrocalc,
      getState
    );
    const customOpacity = customResults(
      'opacities',
      appliedOpacities,
      getState
    );

    dispatch(setCustomDensity(customDensity));
    dispatch(setCustomQuality(customQuality));
    dispatch(setCustomMicrocalc(customMicrocalc));
    dispatch(setCustomOpacities(customOpacity));
  };
};

const sendReport = (
  type,
  confirmed_density,
  confirmed_opacities,
  confirmed_microcalc,
  currentExam,
  t,
  dispatch
) => {
  let actionText = '';

  if (type === 'quality') {
    actionText = confirmed_density
      ? t('revision.quality_send_to_pacs')
      : t('revision.quality_density_send_to_pacs');
  } else if (
    (type === 'microcalc' && confirmed_opacities) ||
    (type === 'opacities' && confirmed_microcalc)
  ) {
    actionText = confirmed_density
      ? t('revision.diagnose_send_to_pacs')
      : t('revision.diagnose_density_send_to_pacs');
  }

  if (actionText) {
    dispatch(
      setActionToConfirm({
        action: () => dispatch(sendReports(type, currentExam, t)),
        text: actionText,
        buttonOk: t('revision.send_report'),
        butonCancel: t('revision.only_save'),
      })
    );
  }
};

// Fetching
export const confirmEvaluation = (created_by, type, state, t) => {
  return async (dispatch, getState) => {
    const { currentExam, exam_list } = getState().examinations;

    const {
      confirmed_density,
      confirmed_quality,
      confirmed_opacities,
      confirmed_microcalc,
    } = getState().results;

    created_by = state ? created_by : 'bbox';
    const confirmed_by = state ? localStorage.getItem('username') : null;

    const body = {
      exam_id: currentExam,
      created_by,
      type,
      confirmed_by,
    };

    const response = await fetchPost('labels', body);

    if (!response.success) {
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
      return;
    }

    updateExamList(exam_list, currentExam, type, state, dispatch);

    dispatch(setConfirmed(type, confirmed_by));
    dispatch(setApplied(type, created_by));

    dispatch(
      setSnackbar({
        msg: response.msg,
        severity: 'success',
      })
    );

    // Send report dialog
    if (state && type !== 'density') {
      sendReport(
        type,
        confirmed_density,
        confirmed_opacities,
        confirmed_microcalc,
        currentExam,
        t,
        dispatch
      );
    }
  };
};

export const saveEvaluation = (type, t) => {
  return async (dispatch, getState) => {
    dispatch(setSavingPending(true));

    // Get required variables
    const {
      customDensity,
      customQuality,
      customOpacities,
      customMicrocalc,
      qualityEvalChanges,
      customQualityOverlays,
    } = getState().labeling;
    const { currentExam, exam_list } = getState().examinations;
    const results = getState().results;
    const username = localStorage.getItem('username');
    const { confirmed_density, confirmed_opacities, confirmed_microcalc } =
      results;
    // Request body
    const body = {
      exam_id: currentExam,
      result_type: type,
      username,
      overlayImages: customQualityOverlays,
      updateQuality: !qualityEvalChanges,
    };

    if (type === 'density') {
      body['results'] = {
        rcc: customDensity.rcc,
        lcc: customDensity.lcc,
        rmlo: customDensity.rmlo,
        lmlo: customDensity.lmlo,
      };
    } else if (type === 'quality') {
      body['results'] = {
        rcc: customQuality.rcc,
        lcc: customQuality.lcc,
        rmlo: customQuality.rmlo,
        lmlo: customQuality.lmlo,
      };
    } else if (type === 'opacities') {
      body['results'] = {
        rcc: customOpacities.rcc,
        lcc: customOpacities.lcc,
        rmlo: customOpacities.rmlo,
        lmlo: customOpacities.lmlo,
      };
    } else if (type === 'microcalc') {
      body['results'] = {
        rcc: customMicrocalc.rcc,
        lcc: customMicrocalc.lcc,
        rmlo: customMicrocalc.rmlo,
        lmlo: customMicrocalc.lmlo,
      };
    } else {
      dispatch(
        setSnackbar({
          msg: `${t('revision.bad_eval_type')}: ${type}`,
          severity: 'warning',
        })
      );
      return;
    }

    const response = await fetchPut('revision', body, 'bbox');
    if (!response.success) {
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
      dispatch(setSavingPending(false));
      return;
    }

    dispatch(
      setSnackbar({
        msg: t('revision.revison_saved'),
        severity: 'success',
      })
    );

    dispatch(setMicrocalcLocalChanges(false));
    dispatch(setOpacitiesLocalChanges(false));
    dispatch(setDensityLocalChanges(false));
    dispatch(setQualityLocalChanges(false));
    dispatch(setQualityEvalChanged(false));
    dispatch(setCustomQualityOverlays({}));

    updateExamList(exam_list, currentExam, type, true, dispatch);

    // Fetch new result
    dispatch(fetchResults());

    dispatch(setSavingPending(false));
    dispatch(setQualityInRevisionMode(false));
    dispatch(setQualityInDrawingMode(false));
    dispatch(setDensityInRevisionMode(false));
    dispatch(setDiagnosticsInRevisionMode(false));

    // Send report
    if (type !== 'density') {
      sendReport(
        type,
        confirmed_density,
        confirmed_opacities,
        confirmed_microcalc,
        currentExam,
        t,
        dispatch
      );
    }
  };
};

export const deleteEvaluation = (type, created_by, t) => {
  return async (dispatch, getState) => {
    if (created_by === 'bbox') {
      dispatch(
        setSnackbar({
          msg: t('Confirm.error_delete'),
          severity: 'warning',
        })
      );
      return;
    }

    const { currentExam, exam_list } = getState().examinations;
    const confirmed_by = getState().results[`confirmed_${type}`];
    const applied = getState().results[`applied_${type}`];

    const response = await fetchDelete('labels', {
      exam_id: currentExam,
      created_by,
      type,
      isApplied: created_by === confirmed_by,
    });

    if (!response.success) {
      dispatch(
        setSnackbar({
          msg: t('Confirm.delete_fail', { message: response.msg }),
          severity: 'error',
        })
      );
      return;
    }

    dispatch(fetchResults());
    dispatch(
      setSnackbar({
        msg: t('Confirm.deleted'),
        severity: 'success',
      })
    );

    updateExamList(exam_list, currentExam, type, false, dispatch);
  };
};

export const setPostSurgery = (exam_id, view_position, postop_arr) => {
  return async (dispatch, getState) => {
    const response = await fetchPut('postop', {
      exam_id,
      view_position,
      postop_arr,
    });

    if (response.success) {
      dispatch(fetchResults());
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'success',
        })
      );
    } else
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
  };
};

export const updateNoTypeRectangles = (proj, rectangles) => {
  return (dispatch, getState) => {
    const noTypeRectangles = getState().labeling.noTypeRectangles || {};
    const currentRectangles = noTypeRectangles[proj] || [];

    const newState = {
      ...noTypeRectangles,
      [proj]: rectangles,
    };
    dispatch(setNoTypeRectangles(newState));
  };
};

// Helpers
function updateExamList(exam_list, currentExam, type, state, dispatch) {
  const idx = exam_list.findIndex((element) => element.id === currentExam);
  const newExamList = [...exam_list];
  const newElement = { ...newExamList[idx] };
  if (newExamList[idx]) {
    newElement[`${type}_confirmed`] = state
      ? localStorage.getItem('username')
      : null;
  }
  newExamList[idx] = newElement;
  dispatch(setExaminationList(newExamList));
}
