import React, { useEffect, useRef, useState } from 'react';
import {
  Drawer,
  IconButton,
  Typography,
  Tooltip,
  MenuItem,
  Paper,
  ClickAwayListener,
  MenuList,
  Grow,
  Popper,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import CloseIcon from '@material-ui/icons/Close';
import * as actions from '../../../store/index';
import SearchBar from './SearchBar/SearchBar';
import DatePicker from './DatePicker/DatePicker';
import CustomDatePicker from './DatePicker/CustomDatePicker';
import ExaminationsListTable from './Tables/ExaminationsListTable';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: 'relative',
    width: theme.drawerWidth,
    height: '100vh',
    zIndex: 200,
    border: 'none',
    boxShadow: '0px 0px 5px 1px rgb(51 51 51)',
    background:
      'linear-gradient(180deg, rgba(66,66,66,1) 0%, rgb(75 75 75) 50%, rgba(66,66,66,1) 100%)',
  },
  drawerPaperLight: {
    position: 'relative',
    width: theme.drawerWidth,
    height: '100vh',
    zIndex: 200,
    border: 'none',
    background:
      'linear-gradient(180deg, #ffffff 0%, #EEF2F6 50%, #ffffff 100%)',
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '2%',
  },

  filtersGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  inputSearchContainer: {
    width: '65%',
    justifyContent: 'center',
    display: 'flex',
  },

  filterDatesGroup: {
    width: '25%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  closeButton: {
    width: '10%',
    display: 'flex',
    justifyContent: 'center',
  },

  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  orderButton: {
    width: '10%',
    display: 'flex',
    justifyContent: 'center',
  },

  tooltipText: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    fontSize: '12px',
  },

  menuItemSelected: {
    '&.Mui-selected': {
      background: '#ffcdd229',
      fontWeight: 'bold',
    },
    '&.Mui-selected:hover': {
      background: '#ffcaca14',
    },
  },
}));

export default function DrawerExamList(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tableEl = useRef(null);
  const anchorRef = useRef(null);

  // Redux State
  const { order, orderBy } = useSelector((state) => state.examinations);
  const { darkState, examinationListDrawer, landscape } = useSelector(
    (state) => state.gui
  );
  const dates = useSelector((state) => state.dates);

  // Component State
  const [showSortingMenu, setShowSortingMenu] = useState(false);

  const sortMapping = {
    date_desc: { orderBy: 'date', order: 'DESC' },
    date_asc: { orderBy: 'date', order: 'ASC' },
    name_desc: { orderBy: 'name', order: 'DESC' },
    name_asc: { orderBy: 'name', order: 'ASC' },
    date_uploaded_des: { orderBy: 'date_uploaded', order: 'DESC' },
    date_uploaded_asc: { orderBy: 'date_uploaded', order: 'ASC' },
  };

  const getDropdownValue = () => {
    return Object.entries(sortMapping).find(
      ([key, value]) => value.orderBy === orderBy && value.order === order
    )?.[0];
  };

  const handleSortChange = (value) => {
    const { orderBy, order } = sortMapping[value] || {};
    if (orderBy && order) {
      dispatch(actions.setOrder(order));
      dispatch(actions.setOrderBy(orderBy));
      setShowSortingMenu(false);
    }
  };

  const SortDropdown = () => (
    <Popper
      open={showSortingMenu}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      style={{ zIndex: 300 }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={() => setShowSortingMenu(false)}>
              <MenuList>
                {Object.keys(sortMapping).map((key) => (
                  <MenuItem
                    key={key}
                    className={classes.menuItemSelected}
                    selected={key === getDropdownValue()}
                    onClick={() => handleSortChange(key)}
                  >
                    {t(`Table.${key}`)}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );

  const closeDrawer = () => {
    dispatch(actions.setExaminationListDrawer(false));
    dispatch(actions.setScrollPosition(tableEl.current?.scrollTop || 0));
  };

  useEffect(() => {
    dispatch(actions.setCompressionDates(null));
    dispatch(actions.setPerformanceDates(null));
  }, [dates.customDate, dates.selectedRange]);

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={examinationListDrawer}
      classes={{
        paper: darkState ? classes.drawerPaper : classes.drawerPaperLight,
      }}
    >
      <div
        className={classes.closeButtonContainer}
        style={{ height: landscape ? '4vh' : '3vh' }}
      >
        <div className={classes.closeButton}>
          <Tooltip
            arrow
            placement="right"
            title={
              <Typography color="inherit" className={classes.tooltipText}>
                {t('Console.close')}
              </Typography>
            }
          >
            <IconButton onClick={closeDrawer} size="small">
              <CloseIcon style={{ width: '16px', height: '16px' }} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className={classes.container}>
        <div className={classes.filtersGroup}>
          <div className={classes.inputSearchContainer}>
            <SearchBar
              placeholder={t('Layout.search')}
              activeFilter={props.activeFilter}
            />
          </div>
          <div className={classes.filterDatesGroup}>
            <DatePicker
              setDates={(dates) =>
                dispatch(actions.setFilterProperty(['dates', dates]))
              }
              customDate={dates.customDate}
              setCustomDate={(date) => dispatch(actions.setCustomDate(date))}
              selectedRange={dates.selectedRange}
              setSelectedRange={(date) =>
                dispatch(actions.setSelectedRange(date))
              }
            />
          </div>
          <div className={classes.orderButton}>
            <Tooltip
              arrow
              placement="right-start"
              title={
                <Typography className={classes.tooltipText}>
                  Sort patient list
                </Typography>
              }
            >
              <IconButton
                ref={anchorRef}
                onClick={() => setShowSortingMenu((prevOpen) => !prevOpen)}
                size="small"
              >
                <SwapVertIcon />
              </IconButton>
            </Tooltip>
            <SortDropdown />
          </div>
        </div>
        {dates.selectedRange === 'custom' && (
          <CustomDatePicker
            setDates={(dates) =>
              dispatch(actions.setFilterProperty(['dates', dates]))
            }
            customDate={dates.customDate}
            setCustomDate={(date) => dispatch(actions.setCustomDate(date))}
          />
        )}
      </div>
      <ExaminationsListTable tableEl={tableEl} />
    </Drawer>
  );
}
