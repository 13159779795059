import React from 'react';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, Paper } from '@material-ui/core';
import Draggable from 'react-draggable';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
  actionButton: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    fontSize: theme.typography.h6.fontSize,
  },

  draggableTitle: {
    cursor: 'move',
    margin: 0,
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    wordBreak: 'break-all',
    maxWidth: '90%',
  },

  DialogContent: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    overflow: 'hidden',
    overflowY: 'auto',
  },

  DialogHideScrollContent: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    overflow: 'hidden',
  },
}));

const styles = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
    transform: 'scale(1.1)',
  },
  title: {
    letterSpacing: '0.2rem',
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography className={classes.title} variant="h5">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function DraggableDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      onClose={props.onClose}
      open={props.open}
      fullWidth
      maxWidth={props.maxWidth}
      className={props.className}
      style={props.style}
    >
      <DialogTitle
        className={classes.draggableTitle}
        id="draggable-dialog-title"
        onClose={props.onClose}
      >
        {props.title}
      </DialogTitle>

      <DialogContent
        dividers
        className={
          props.contentStyle
            ? classes.DialogHideScrollContent
            : classes.DialogContent
        }
        style={props.customStyle}
      >
        {props.children}
      </DialogContent>
      <DialogActions>
        {props.actions.map((action) => {
          if (action !== false) {
            return (
              <Button
                data-test-id="dialog-actions"
                className={classes.actionButton}
                onClick={action.onClick}
                color={action.color}
                key={action.label}
              >
                {action.label}
              </Button>
            );
          }
          return null;
        })}
      </DialogActions>
    </Dialog>
  );
}
