import React from 'react';
import { Grid, Button, Tooltip, ButtonGroup } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  GridItem: {
    height: '100%',
  },
}));

export default function ButtonGroupQuality(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const examinationListDrawer = useSelector(
    (state) => state.gui.examinationListDrawer
  );

  return (
    <Grid
      wrap="nowrap"
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={1}
    >
      <Grid className={classes.GridItem} item>
        <ButtonGroup disableElevation variant="contained" size="small">
          <Tooltip
            arrow
            placement="bottom"
            title={`${t('Dashboard.view')} ${t(
              'Dashboard.quality_per_projections'
            )}`}
          >
            <Button
              variant="contained"
              onClick={() =>
                props.qualityData === 'projections'
                  ? props.setQualityData('studies')
                  : props.setQualityData('projections')
              }
              style={{
                backgroundColor:
                  props.qualityData === 'projections'
                    ? '#2C7075'
                    : 'transparent',
                color:
                  props.qualityData === 'projections' ? 'white' : '#2C7075',
                border: `1px solid #2C7075`,
                height: '25px',
              }}
              id="quality_category"
              size="small"
            >
              {t('Dashboard.proj_short')}
            </Button>
          </Tooltip>
          <Tooltip
            arrow
            placement="bottom"
            title={`${t('Dashboard.view')} ${t(
              'Dashboard.quality_per_studies'
            )}`}
          >
            <Button
              variant="contained"
              onClick={() =>
                props.qualityData === 'projections'
                  ? props.setQualityData('studies')
                  : props.setQualityData('projections')
              }
              style={{
                backgroundColor:
                  props.qualityData === 'studies' ? '#2C7075' : 'transparent',
                color: props.qualityData === 'studies' ? 'white' : '#2C7075',
                border: `1px solid #2C7075 `,
                height: '25px',
              }}
              id="quality_category"
              size="small"
            >
              {t('Dashboard.study_short')}
            </Button>
          </Tooltip>
        </ButtonGroup>
      </Grid>
    </Grid>
  );
}
