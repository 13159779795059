
function _dot_product(a, b) {
    return a.map((_, i) => a[i] * b[i]).reduce((m, n) => m + n);
}


function _get_pecto_vector(point_cranial, point_caudal, point_nipple, PNL_FROM_EDGE) {
    const v_pecto = [point_cranial[0]-point_caudal[0],
                     point_cranial[1]-point_caudal[1]];

    const v_nipple = [point_nipple[0]-point_caudal[0],
                      point_nipple[1]-point_caudal[1]];
    
    const length = _dot_product(v_pecto, v_nipple) / _dot_product(v_pecto, v_pecto);
    
    let vector = [length * v_pecto[0], length * v_pecto[1]];

    if (PNL_FROM_EDGE && length < 0) { 
        const y = vector[0] * vector[0] / vector[1] + vector[1]
        vector = [0, y];
    }

    return vector;
}

function getProjectionPoint(pecto_cranial, pecto_caudal, nipple, size_x, size_y, flip, PNL_FROM_EDGE) {
    try {
        const point_cranial_mm = [pecto_cranial[0] * size_x, 0];
        const point_caudal_mm = flip ? [size_x, pecto_caudal[1] * size_y] : [0, pecto_caudal[1] * size_y];
        const point_nipple_mm = [nipple[0] * size_x, nipple[1] * size_y];
    
        const pecto_vector_mm = _get_pecto_vector(point_cranial_mm,
                                                  point_caudal_mm,
                                                  point_nipple_mm,
                                                  PNL_FROM_EDGE)

        const point_projection_mm = [point_caudal_mm[0] + pecto_vector_mm[0],
                                     point_caudal_mm[1] + pecto_vector_mm[1]];

        return point_projection_mm;
    } catch {
        return [null, null];
    }
}

function findPectNippleLineMLO(pecto_cranial, pecto_caudal, nipple, size_x, size_y, flip){
    const point_projection_mm = getProjectionPoint(pecto_cranial, pecto_caudal, nipple, size_x, size_y, flip);
    const point_nipple_mm = [nipple[0] * size_x, nipple[1] * size_y];
    const x = point_projection_mm[0] - point_nipple_mm[0];
    const y = point_projection_mm[1] - point_nipple_mm[1];

    return Math.sqrt(x*x + y*y);
}

function findPectoAngle(pecto_cranial, pecto_caudal, size_in_mm, flip) {
    let elevation = flip ? 1 - pecto_cranial[0] : pecto_cranial[0];
    elevation *= size_in_mm[0];
    const progression = pecto_caudal[1] * size_in_mm[1];
    if (progression === 0)
        return null;
    const angle = Math.atan2(elevation, progression) * 180 / Math.PI;
    return angle;
}

function findNippleAngle(center_line, point_nipple, flip, size_in_mm) {
    try {
        let nipple_x = point_nipple[0];
        if (flip) 
            nipple_x = (1 - point_nipple[0])

        nipple_x = nipple_x * size_in_mm[0];
        const nipple_y = point_nipple[1] * size_in_mm[1];
        const center_y = center_line * size_in_mm[1];

        const elevation = nipple_y - center_y;
        const progress = nipple_x;
        const angle = -Math.atan2(elevation, progress) * 180 / Math.PI;

        return angle
    } catch(err) {
        console.log(err);
        return null
    }
}

export { 
    findPectNippleLineMLO,
    findPectoAngle,
    getProjectionPoint,
    findNippleAngle
};
