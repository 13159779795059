import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { TextField } from '@material-ui/core';
import { Search, Clear, BorderRight } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useTranslation } from 'react-i18next';
import * as actions from '../../../../store/index';

const useStyles = makeStyles((theme) => ({
  SearchBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      WebkitBoxShadow: `0 0 0 100px ${theme.palette.complementary.mainDark} inset`,
      borderRadius: 'initial',
    },
  },

  textField: {
    margin: theme.spacing(1),
    width: '40vw',
    border: 'none !important',
    '&.MuiInput-underline': {
      borderBottom: 'none !important',
    },
    '& .MuiInputBase-input::placeholder': {
      fontSize: '12px',
      color: theme.palette.text.secondary,
    },
  },
}));

function SearchBar(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Store state
  const filterPrompt = useSelector((state) => state.filter.search);

  // local state
  const [localPrompt, setLocalPrompt] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(null);

  // Effects
  useEffect(() => {
    if (typingTimeout) clearTimeout(typingTimeout);

    const timeout = setTimeout(() => {
      dispatch(actions.setFilterProperty(['search', localPrompt || null]));
    }, 800);

    setTypingTimeout(timeout);
  }, [localPrompt]);

  useEffect(() => {
    if (filterPrompt !== localPrompt) {
      setLocalPrompt(filterPrompt || '');
    }
  }, [filterPrompt]);

  return (
    <div className={[classes.SearchBar, props.className].join(' ')}>
      <TextField
        size="small"
        id="search-examinations"
        placeholder={t('DatePicker.search_patient')}
        className={classes.textField}
        value={localPrompt}
        onChange={(event) => setLocalPrompt(event.target.value)}
        variant="outlined"
        InputProps={{
          style: {
            paddingRight: '4px',
            paddingLeft: '4px',
          },
          startAdornment: (
            <InputAdornment position="start">
              <Search fontSize="small" />
            </InputAdornment>
          ),
          endAdornment: (
            <>
              <InputAdornment
                style={{ cursor: 'pointer' }}
                position="end"
                onClick={() => setLocalPrompt('')}
              >
                {localPrompt && <Clear fontSize="small" />}
              </InputAdornment>
            </>
          ),
        }}
      />
    </div>
  );
}

export default SearchBar;
