import React, { useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { CssBaseline, useMediaQuery, Container } from "@material-ui/core";
import {
  MuiThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@material-ui/core/styles";

import { useSelector, useDispatch } from "react-redux";
import LoginForm from "./components/LoginRegister/LoginForm";
import LoginWithToken from "./components/LoginRegister/LoginWithToken";
import RegisterForm from "./components/LoginRegister/RegisterForm";
import Logout from "./components/logout/Logout";
import Layout from "./components/Layout/Layout";
import { setLandscape, fetchGlobalSettings } from "./store";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import theme from "@amcharts/amcharts4/.internal/themes/animated";
import { getColorPalette } from "./palette";
import { createCustomTheme } from "./theme";
import { grey } from "@material-ui/core/colors";
import { CLIENT, CLIENT_ID } from "../src/config";
import * as actions from "./store/index";
import { useTranslation } from "react-i18next";
import IFUPdf from "./components/IFU/IFUPdf";
import ResultView from "./components/Layout/ResultView/ResultView";

const App = () => {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const darkState = useSelector((store) => store.gui.darkState);
  const username = localStorage.getItem("username");
  const landscape = useMediaQuery("(min-aspect-ratio: 1/1)");
  const smallScrean = useMediaQuery("(max-width: 800px)");
  const softwareVersion = process.env.REACT_APP_SOFTWARE_VERSION || "2.0.0";
  const languageSelected = useSelector((store) => store.gui.language);

  const dispatch = useDispatch();

  const [
    mainPrimaryColor,
    mainPrimaryColorDark,
    mainSecondaryColor,
    mainSecondaryColorDark,
    mainBackgroundColor,
    mainPaperColor,
    accentColor,
  ] = getColorPalette();

  let myTheme = createCustomTheme(
    mainBackgroundColor,
    mainPaperColor,
    accentColor,
    mainPrimaryColor,
    mainPrimaryColorDark,
    mainSecondaryColor,
    mainSecondaryColorDark
  );

  myTheme.overrides = {
    MuiCssBaseline: {
      "@global": {
        "*::-webkit-scrollbar": {
          width: "0.4em",
          scrollBehavior: "smooth",
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: grey[600],
          borderRadius: "5px",
        },
      },
    },

    MuiButton: {
      root: {
        fontSize: myTheme.fonts.responsiveMedium,
        borderRadius: "5px",
      },
      containedSizeSmall: {
        fontSize: myTheme.fonts.responsiveMedium,
        borderRadius: "5px",
      },
      outlinedSizeSmall: {
        fontSize: myTheme.fonts.responsiveMedium,
        borderRadius: "5px",
      },
      iconSizeMedium: {
        "& > *:first-child": {
          fontSize: myTheme.fonts.responsiveBig,
        },
      },
    },
  };

  if (smallScrean) myTheme.drawerWidth -= 50;

  useEffect(() => {
    dispatch(fetchGlobalSettings());
    i18n.changeLanguage(languageSelected);
  }, [languageSelected]);

  useEffect(() => {
    dispatch(setLandscape(landscape));
  }, [landscape, dispatch]);

  useEffect(() => {
    localStorage.setItem("accountName", CLIENT);
    localStorage.setItem("accountID", CLIENT_ID);
    localStorage.setItem("softwareVersion", softwareVersion);
  }, [history, dispatch]);

  myTheme = responsiveFontSizes(myTheme);

  return (
    <MuiThemeProvider theme={myTheme}>
      <CssBaseline />
      <ErrorBoundary t={t}>
        <Switch>
          <Route path={`/bbox/ifu_:lang`}>
            {username ? <IFUPdf lang={i18n.language} /> : <Logout />}
          </Route>

          <Route path="/bbox" render={(props) => <Layout {...props} />} />

          <Route path="/study/:username/:password/:study_id">
            <ResultView />
          </Route>

          <Route path="/study/:token/:study_id">
            <ResultView />
          </Route>

          <Route path="/register">
            <RegisterForm />
          </Route>

          {/* <Route path='/login/:token'>
            <LoginWithToken />
          </Route> */}

          <Route path="/login" render={(props) => <LoginForm {...props} />} />

          <Route path="/logout">
            <Logout />
          </Route>

          <Redirect to="/login" />
        </Switch>
      </ErrorBoundary>
    </MuiThemeProvider>
  );
};

export default App;
