import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Button,
  Typography,
  TextField,
  Link,
  Paper,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Chip,
  IconButton,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Logo from '../../resources/coorporate_images/brayz_logo_login.png';
import { fetchPost } from '../../helpers/fetching';
import MediaLinks from './MediaLinks';
import NeuroConnector from './../../resources/coorporate_images/neuro_network_small.png';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { CopyrightShort } from './CopyrightShort';
import { useSelector } from 'react-redux';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    overflow: 'hidden',
  },
  image: {
    background:
      'linear-gradient(90deg, rgb(95 87 87) 0%, rgb(66, 66, 66) 100%)',
  },

  image_light: {
    background:
      'linear-gradient(270deg, rgb(255 255 255) 0%, rgb(215 215 215) 100%)',
  },

  paper: {
    margin: theme.spacing(6, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    width: 'auto',
    height: '20vh',
  },

  form: {
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      WebkitBoxShadow: `0 0 0 100px ${theme.palette.complementary.mainDark} inset`,
      borderRadius: 'initial',
    },
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    width: '200px',
  },
  loginInfo: {
    textDecoration: 'none',
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
  },

  errorText: {
    textDecoration: 'none',
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
  },

  register: {
    textDecoration: 'none',
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
  },
  mediaLinks: {
    marginBottom: theme.spacing(1),
  },
  disclaimer_text: {
    fontSize: '0.7rem !important',
    fontStyle: 'italic',
    lineHeight: 1,
  },

  text_field_login: {
    width: '80%',
  },

  connector_image: {
    height: '70vh',
    alignSelf: 'self-end',
    opacity: '70%',
  },
}));

export default function LoginForm(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const darkState = useSelector((store) => store.gui.darkState);

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [disclaimer, setDisclaimer] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const onLoginChange = (event) => {
    setLogin(event.target.value);
  };

  const onPasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const onSubmit = async () => {
    const response = await fetchPost('login', { login, password });
    if (response.success) {
      localStorage.setItem('username', response.username);
      localStorage.setItem('role', response.role);
      localStorage.setItem('annotation', response.annotation);
      localStorage.setItem('diagnostics', response.diagnostics);
      localStorage.setItem('aliases', response.aliases);
      localStorage.setItem('last_login', response.last_login);
      props.history.push(`/bbox/results/${'details'}`);
    } else setLoginError(response.msg);
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      onSubmit();
    }
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Grid container component="main" className={classes.root}>
      <Grid
        item
        xs={3}
        className={darkState ? classes.image : classes.image_light}
      />

      <Grid
        container
        direction="column"
        item
        xs={6}
        component={Paper}
        elevation={0}
        square
        alignItems="center"
        justifyContent="space-between"
        style={{
          zIndex: '1000',
          backgroundColor: theme.palette.complementary.mainDark,
        }}
      >
        <img className={classes.avatar} src={Logo} alt="logo missing" />

        <div style={{ height: '30px' }}>
          {loginError && (
            <Chip
              icon={<ErrorOutlineIcon />}
              label={loginError}
              onDelete={() => setLoginError('')}
              color="primary"
              variant="default"
              className={classes.errorText}
            />
          )}
        </div>
        <FormGroup className={classes.form}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            className={classes.text_field_login}
            id="login"
            onChange={onLoginChange}
            label={t('LoginRegister.username')}
            name="username"
            autoComplete="username"
            autoFocus
            data-test-id="login-username"
            InputProps={{
              className: classes.autoFillInput,
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            className={classes.text_field_login}
            name="password"
            label={t('LoginRegister.password')}
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete={t('LoginRegister.autocomplete_password')}
            onKeyDown={disclaimer ? keyPress : () => {}}
            onChange={onPasswordChange}
            data-test-id="login-password"
            InputProps={{
              className: classes.autoFillInput,

              endAdornment: (
                <IconButton onClick={handlePasswordVisibility} edge="end">
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              ),
            }}
          />

          <FormControlLabel
            className={classes.disclaimer_text}
            control={<Checkbox />}
            label={
              <span style={{ fontSize: '0.7rem' }}>
                {t('LoginRegister.disclaimer')}
              </span>
            }
            onChange={(e) => setDisclaimer(e.target.checked)}
            style={{ color: darkState ? '#dfdfdf' : 'gray' }}
          />
        </FormGroup>

        <Button
          disabled={disclaimer ? false : true}
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={onSubmit}
          size="large"
          data-test-id="login-button"
        >
          {t('LoginRegister.login')}
        </Button>

        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          className={classes.loginInfo}
        >
          {t('LoginRegister.first_time')}
          <RouterLink
            className={classes.register}
            to="/register"
            data-test-id="go-to-register-button"
          >
            {t('LoginRegister.register')}
          </RouterLink>
        </Typography>
        <Grid
          className={classes.mediaLinks}
          item
          container
          direction="column"
          alignItems="center"
          wrap="nowrap"
          justifyContent="center"
          spacing={1}
        >
          <Grid item>
            <CopyrightShort />
          </Grid>
          <Grid item>
            <MediaLinks />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={3}
        style={{
          display: 'flex',
          background: darkState
            ? 'linear-gradient(270deg, rgb(95 87 87) 0%, rgb(66, 66, 66) 100%)'
            : 'linear-gradient(270deg, rgb(171 171 171) 0%, rgb(255 255 255) 100%)',
        }}
      >
        <img
          className={classes.connector_image}
          src={NeuroConnector}
          alt="b_rayz connector"
        />
      </Grid>
    </Grid>
  );
}
