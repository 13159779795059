import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  Avatar,
  Tooltip,
  Typography,
  Divider,
  Fab,
  IconButton,
  CardHeader,
  CardContent,
  ClickAwayListener,
  CardActions,
  Card,
} from '@material-ui/core';
import SummaryParagraphHeader from '../SummaryParagraphHeader';
import { useTranslation } from 'react-i18next';
import * as actions from '../../../../../store/index';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import BDensity from '../../../../../resources/icons/BDensity.png';
import { densityColor, highDensity } from '../../../../../helpers/density';
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentModuleStatus } from '../SummaryCommon/SummaryCommon';
import TooltipBadgeFab from '../../DanaiView/Common/DiagnoseViewCommon';
import ReviewSelect from '../../CardActions/ReviewSelect/ReviewSelect';
import {
  DIAGNOSTICS_MICROCALC,
  DIAGNOSTICS_OPACITIES,
  PREMIUM_DATA_ANNOTATION,
} from '../../../../../config';
import WarningIcon from '@material-ui/icons/Warning';
import { reviewerName } from '../../../../../helpers/reviewerName';

const useStyles = makeStyles((theme) => ({
  projections_density: {
    position: 'absolute',
    display: 'flex',
    height: '50%',
    width: '50%',
    color: theme.palette.complementary.mainDark,
  },

  projDensityText: {
    fontSize: '1.2rem',
    margin: '5% 20%',
  },

  tooltipText: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    fontSize: theme.fonts.responsiveMedium,
  },

  density_module_fabs: {
    height: '26px',
    width: '26px',
    minWidth: '26px',
    minHeight: '26px',
    margin: '4px',
    cursor: 'help',
  },

  density_module_annotator_fabs: {
    height: '26px',
    width: '26px',
    minWidth: '26px',
    minHeight: '26px',
    margin: '4px',
    '&:hover': {
      background: `${theme.palette.complementary.turquoise} !important`,
      boxShadow: '0px 0px 5px 0px #d7c2c2',
    },
  },

  tooltip_text: {
    letterSpacing: '0.09rem',
    fontSize: '12px',
  },

  reviewer_badge: {
    height: '14px',
    minHeight: '14px',
    fontSize: theme.fonts.responsiveSmall,
    marginBottom: '2px',
    width: '100%',
    textOverflow: 'ellipsis',
  },

  reviewer_chip_small: {
    marginLeft: '5px',
    minWidth: '45px',
    maxWidth: '55px',
  },

  reviewer_chip_big: {
    marginLeft: '10px',
    minWidth: '70px',
  },

  summary_edit_fabs: {
    height: '26px',
    width: '26px',
    minWidth: '26px',
    minHeight: '26px',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: '0px 0px 5px 0px #d7c2c2',
    },
  },

  module_fab_container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '4px',
  },

  card_main_container: {
    margin: '0',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

export default function DensitySummary(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const anchorDensityRef = useRef();

  const [reviewerTooltip, setReviewerTooltip] = useState(false);
  const [densityPopperOpen, setDensityPopperOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const { applied_density, confirmed_density, shown_density } = useSelector(
    (state) => state.results
  );
  const { darkState, landscape } = useSelector((store) => store.gui);

  const isConfirmed = applied_density === 'bbox' && confirmed_density;
  const isRelabeled = confirmed_density && applied_density !== 'bbox';
  const shownDensity = shown_density || applied_density;
  const annotatorRole =
    PREMIUM_DATA_ANNOTATION && localStorage.getItem('annotation') === 'true';
  const densityAsymmetry = true;

  const densityMap = {
    A: 0,
    B: 1,
    C: 2,
    D: 3,
  };

  // Effects
  const compareDensityClasses = (densityProj) => {
    const rightProjections = ['rcc', 'rmlo'];
    const leftProjections = ['lcc', 'lmlo'];

    const getMaxDensity = (projections) => {
      let maxDensity = null;

      projections.forEach((projection) => {
        if (densityProj?.[projection]?.DensClass) {
          const densClassValue = densityMap[densityProj[projection].DensClass];
          if (maxDensity === null || densClassValue > maxDensity) {
            maxDensity = densClassValue;
          }
        }
      });
      return maxDensity;
    };

    const maxRightDensity = getMaxDensity(rightProjections);
    const maxLeftDensity = getMaxDensity(leftProjections);

    if (maxRightDensity === null && maxLeftDensity === null) {
      return null;
    }
    if (maxRightDensity === null || maxLeftDensity === null) {
      return null;
    }
    const densityDifference = Math.abs(maxRightDensity - maxLeftDensity);

    if (densityDifference === 0) {
      return null;
    } else if (densityDifference === 1) {
      return 1;
    } else {
      return 2;
    }
  };

  useEffect(() => {
    setDensityPopperOpen(false);
  }, []);

  const handlePopperToggle = () => {
    setReviewerTooltip(false);
    setDensityPopperOpen((prevOpen) => !prevOpen);
  };

  const handlePopperClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setDensityPopperOpen(false);
  };

  const clickAwayHandler = () => {
    setReviewerTooltip(false);
    setDensityPopperOpen(false);
    setAnchorEl(null);
  };

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  let density;
  if (!props.zoomedProjection) density = highDensity(props.results);
  else
    density =
      props.results?.[props.zoomedProjection]?.DensClass ||
      props.results?.[props.zoomedProjection]?.DensMax;

  const handleEditButtonClick = () => {
    dispatch(actions.setTabsIndex(2));
    dispatch(actions.setDensityInRevisionMode(true));
  };

  const handleTitleClick = () => {
    dispatch(actions.setTabsIndex(2));
    dispatch(actions.setDensityInRevisionMode(true));
  };

  const onConfirmClick = () => {
    const state = shown_density === applied_density && confirmed_density;
    dispatch(actions.confirmEvaluation(shownDensity, 'density', !state, t));
  };

  return (
    <Card
      className={classes.card_main_container}
      style={{
        width: props.allPremiumShown || props.diagnosticsShown ? '35%' : '100%',
        height: !props.allPremiumShown && '100%',
      }}
    >
      <CardHeader
        style={{
          padding: '0',
          background: theme.palette.complementary.generalTitleBackgrounds,
        }}
        title={
          <div
            style={{
              display: 'flex',
              width: '100%',
              padding: '0px 8px 0 16px',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <SummaryParagraphHeader
                header={t('b_box.b_density')}
                clickable={annotatorRole}
                onClick={() => annotatorRole && handleTitleClick()}
                tootlipTitle={t('ResultView.view_more_density')}
              />
              <ClickAwayListener
                onClickAway={() => clickAwayHandler('density')}
                mouseEvent="onClick"
              >
                <div
                  className={
                    props.diagnosticsShown
                      ? classes.reviewer_chip_small
                      : classes.reviewer_chip_big
                  }
                >
                  <TooltipBadgeFab
                    content={
                      <>
                        <Typography
                          className={classes.tooltip_text}
                          color="inherit"
                        >
                          {t('ResultView.current_results_shown')}:{' '}
                          {reviewerName(shownDensity)}
                        </Typography>
                        <Divider />
                        <Typography
                          className={classes.tooltip_text}
                          color="inherit"
                        >
                          {t('ResultView.current_applied')}:{' '}
                          {reviewerName(applied_density)}
                        </Typography>
                        <p>{t('ResultView.change_reviewer')}</p>
                      </>
                    }
                    open={reviewerTooltip}
                    badgeContent={shownDensity}
                    onClick={() => handlePopperToggle('density')}
                    onMouseEnter={() => setReviewerTooltip(true)}
                    onMouseLeave={() => setReviewerTooltip(false)}
                    popperContent={
                      <ReviewSelect
                        type="density"
                        setDensityPopperOpen={setDensityPopperOpen}
                      />
                    }
                    anchorRef={anchorDensityRef}
                    popperOpen={densityPopperOpen}
                    classes={classes}
                    theme={theme}
                    t={t}
                  />
                </div>
              </ClickAwayListener>
            </div>
            {annotatorRole && (
              <Tooltip
                arrow
                placement="left-end"
                title={
                  <Typography className={classes.tooltip_text} color="inherit">
                    {t('ResultView.tooltip_revise')}
                  </Typography>
                }
              >
                <IconButton
                  size="small"
                  onClick={() => handleEditButtonClick()}
                  className={classes.summary_edit_fabs}
                >
                  <EditIcon
                    fontSize="medium"
                    style={{
                      width: '18px',
                      height: '18px',
                      color: 'white',
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>
        }
      />

      <CardContent style={{ padding: '0' }}>
        <div
          style={{
            height: '10%',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            marginRight: '10px',
          }}
        >
          {compareDensityClasses(props.results) &&
            compareDensityClasses(props.results) !== 0 && (
              <Tooltip
                arrow
                title={
                  <Typography className={classes.tooltip_text}>
                    {t('ResultView.density_asymmetry')}
                  </Typography>
                }
              >
                <WarningIcon
                  fontSize="small"
                  style={{
                    cursor: 'help',
                    position: 'relative',
                    right: '0',
                    top: '16px',

                    fill:
                      compareDensityClasses(props.results) === 1
                        ? '#ff9800'
                        : '#e74034',
                  }}
                />
              </Tooltip>
            )}
        </div>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Tooltip
            title={`${t('ResultView.overall_density')}: ${density}`}
            arrow
          >
            <div
              style={{
                position: 'relative',
                width: !landscape
                  ? '6vh'
                  : (DIAGNOSTICS_MICROCALC || DIAGNOSTICS_OPACITIES) &&
                    localStorage.getItem('diagnostics') === 'true'
                  ? '9vh'
                  : '11vh',
                height: !landscape
                  ? '6vh'
                  : (DIAGNOSTICS_MICROCALC || DIAGNOSTICS_OPACITIES) &&
                    localStorage.getItem('diagnostics') === 'true'
                  ? '9vh'
                  : '11vh',
                borderRadius: '50%',
                overflow: 'hidden',
                cursor: annotatorRole ? 'pointer' : 'default',
              }}
              onMouseEnter={handleHover}
              onMouseLeave={handleMouseLeave}
              onClick={() => annotatorRole && handleTitleClick()}
            >
              {isHovered ? (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textTransform: 'uppercase',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: densityColor(
                        props.results?.rcc?.DensClass,
                        theme
                      ),
                      top: 0,
                      left: 0,
                      borderBottom: `1px solid ${theme.palette.complementary.mainDark}`,
                      borderRight: `1px solid ${theme.palette.complementary.mainDark}`,
                      justifyContent: 'flex-end',
                      alignItems: 'flex-end',
                    }}
                    className={classes.projections_density}
                  >
                    <p className={classes.projDensityText}>
                      {props.results?.rcc?.DensClass}
                    </p>
                  </div>
                  <div
                    style={{
                      backgroundColor: densityColor(
                        props.results?.lcc?.DensClass,
                        theme
                      ),
                      top: 0,
                      right: 0,
                      borderBottom: `1px solid ${theme.palette.complementary.mainDark}`,
                      justifyContent: 'flex-start',
                      alignItems: 'flex-end',
                    }}
                    className={classes.projections_density}
                  >
                    <p className={classes.projDensityText}>
                      {props.results?.lcc?.DensClass}
                    </p>
                  </div>
                  <div
                    style={{
                      backgroundColor: densityColor(
                        props.results?.rmlo?.DensClass,
                        theme
                      ),
                      bottom: 0,
                      left: 0,
                      borderRight: `1px solid ${theme.palette.complementary.mainDark}`,
                      justifyContent: 'flex-end',
                      alignItems: 'flex-start',
                    }}
                    className={classes.projections_density}
                  >
                    <p className={classes.projDensityText}>
                      {props.results?.rmlo?.DensClass}
                    </p>
                  </div>
                  <div
                    style={{
                      backgroundColor: densityColor(
                        props.results?.lmlo?.DensClass,
                        theme
                      ),
                      bottom: 0,
                      right: 0,
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                    className={classes.projections_density}
                  >
                    <p className={classes.projDensityText}>
                      {props.results?.lmlo?.DensClass}
                    </p>
                  </div>
                </div>
              ) : (
                <Avatar
                  style={{
                    backgroundColor: densityColor(density, theme),
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <div
                    style={{
                      fontSize: '3rem',
                      textAlign: 'center',
                      textTransform: 'uppercase',
                    }}
                  >
                    {density}
                  </div>
                </Avatar>
              )}
            </div>
          </Tooltip>
        </Grid>
      </CardContent>

      <CardActions className={classes.module_fab_container}>
        <Tooltip
          title={
            <>
              <Typography
                className={classes.tooltipText}
                style={{ fontWeight: 'bold' }}
              >
                {
                  getCurrentModuleStatus(
                    applied_density,
                    shownDensity,
                    isConfirmed,
                    isRelabeled,
                    t('ResultView.density'),
                    theme,
                    t
                  ).currentApplied
                }
              </Typography>
              {annotatorRole && (
                <>
                  {' '}
                  <Divider style={{ margin: '3% 0' }} />
                  <Typography className={classes.tooltipText}>
                    {
                      getCurrentModuleStatus(
                        applied_density,
                        shownDensity,
                        isConfirmed,
                        isRelabeled,
                        t('ResultView.density'),
                        theme,
                        t
                      ).tooltipAction
                    }
                  </Typography>
                </>
              )}
            </>
          }
          arrow
          placement="top-start"
        >
          <span>
            <Fab
              size="small"
              disabled={!annotatorRole}
              onClick={() => onConfirmClick()}
              color="primary"
              className={
                annotatorRole
                  ? classes.density_module_annotator_fabs
                  : classes.density_module_fabs
              }
              style={{
                color: '#fff',
                background: `${
                  getCurrentModuleStatus(
                    applied_density,
                    shownDensity,
                    isConfirmed,
                    isRelabeled,
                    t('ResultView.density'),
                    theme,
                    t
                  ).color
                }`,
              }}
            >
              {
                getCurrentModuleStatus(
                  applied_density,
                  shownDensity,
                  isConfirmed,
                  isRelabeled,
                  t('ResultView.density'),
                  theme,
                  t
                ).fabIcon
              }
            </Fab>
          </span>
        </Tooltip>
      </CardActions>
    </Card>
  );
}
