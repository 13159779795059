import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Button,
  IconButton,
} from '@material-ui/core';
import { ExpandMore, Lock } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SaveIcon from '@material-ui/icons/Save';
import { setSnackbar } from '../../../store';
import { fetchPut } from '../../../helpers/fetching';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.texts.secondary,
  },

  form: {
    width: '70%',
    margin: 'auto',
  },

  passwordButtton: {
    margin: '5% 0 5% 0',
  },

  listHeader: {
    fontSize: theme.fonts.responsiveMedium,
    fontWeight: 'bold',
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.texts.primary,
  },
}));

function ChangePassword() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const darkState = useSelector((store) => store.gui.darkState);

  const [passwordForm, setPasswordform] = useState({
    current: '',
    new: '',
    confirm: '',
  });
  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    confirm: false,
  });

  const onPasswordFormChange = (event) => {
    const { name, value } = event.target;

    setPasswordform((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePasswordVisibility = (field) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const onPasswordSubmit = async () => {
    if (passwordForm.new.length < 8) {
      dispatch(
        setSnackbar({
          msg: t('UserPanel.snack_password_short'),
          severity: 'warning',
        })
      );
      return;
    }

    if (passwordForm.new !== passwordForm.confirm) {
      dispatch(
        setSnackbar({
          msg: t('UserPanel.snack_password_match'),
          severity: 'warning',
        })
      );

      return;
    }

    const response = await fetchPut('set_password', {
      ...passwordForm,
      username: localStorage.getItem('username'),
    });
    if (response.success) {
      dispatch(
        setSnackbar({
          msg: t('UserPanel.snack_password_changed'),
          severity: 'success',
        })
      );
      setPasswordform({
        current: '',
        new: '',
        confirm: '',
      });
    } else {
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
    }
  };

  return (
    <div className={classes.profile_container}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <Lock className={classes.icon} />
        </Grid>
        <Grid item>
          <Typography className={classes.listHeader}>
            {t('UserPanel.change_password')}
          </Typography>
        </Grid>
      </Grid>

      <Grid container direction="column" justifyContent="flex-start">
        <Grid item>
          <TextField
            fullWidth
            type={showPassword.current ? 'text' : 'password'}
            variant="outlined"
            margin="normal"
            size="small"
            onChange={onPasswordFormChange}
            label={t('UserPanel.current')}
            name="current"
            value={passwordForm.current}
            InputProps={{
              className: classes.autoFillInput,
              style: { backgroundColor: darkState ? '#515151' : '#e7e7e7' },
              endAdornment: (
                <IconButton
                  onClick={() => handlePasswordVisibility('current')}
                  edge="end"
                >
                  {showPassword.current ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            type={showPassword.new ? 'text' : 'password'}
            margin="normal"
            size="small"
            name="new"
            onChange={onPasswordFormChange}
            label={t('UserPanel.new')}
            variant="outlined"
            value={passwordForm.new}
            InputProps={{
              className: classes.autoFillInput,
              style: { backgroundColor: darkState ? '#515151' : '#e7e7e7' },
              endAdornment: (
                <IconButton
                  onClick={() => handlePasswordVisibility('new')}
                  edge="end"
                >
                  {showPassword.new ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            type={showPassword.confirm ? 'text' : 'password'}
            margin="normal"
            size="small"
            name="confirm"
            onChange={onPasswordFormChange}
            label={t('UserPanel.confirm_pass')}
            variant="outlined"
            value={passwordForm.confirm}
            InputProps={{
              className: classes.autoFillInput,
              style: { backgroundColor: darkState ? '#515151' : '#e7e7e7' },
              endAdornment: (
                <IconButton
                  onClick={() => handlePasswordVisibility('confirm')}
                  edge="end"
                >
                  {showPassword.confirm ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid container justifyContent="flex-end" item>
          <Grid item>
            <Button
              startIcon={<SaveIcon />}
              className={classes.passwordButtton}
              variant="outlined"
              color="primary"
              onClick={onPasswordSubmit}
            >
              {t('UserPanel.confirm')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default ChangePassword;
