import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import 'react-datepicker/dist/react-datepicker.css';
import { formatDate } from '../../../../helpers/formatDate.js';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setOrder, setOrderBy } from '../../../../store';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },

  menu_item_dates: {
    fontSize: 12,
    '&.Mui-selected': {
      background: '#ffcdd229',
      fontWeight: 'bold',
    },
    '&.Mui-selected:hover': {
      background: '#ffcaca14',
    },
  },

  selectIcon: {
    right: '0',
  },
}));

const DatePicker = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { customDate, setCustomDate } = props;
  const [datePreset, setDatePreset] = useState('');
  const dispatch = useDispatch();
  const { order, orderBy } = useSelector((state) => state.examinations);

  useEffect(() => {
    if (!props.selectedRange) {
      setDatePreset('day');
    }
    if (props.selectedRange) {
      setDatePreset(props.selectedRange);
    }
  }, [props]);

  const handleDatePreset = (newPreset) => {
    const todayBegin = formatDate(new Date(), '00:00');
    const todayEnd = formatDate(new Date(), '23:59');
    switch (newPreset) {
      case 'day':
        props.setDates([todayBegin, todayEnd]);
        props.setSelectedRange('day');
        break;
      case 'week':
        const lastWeek = new Date();
        lastWeek.setDate(lastWeek.getDate() - 6);
        props.setDates([formatDate(lastWeek, '00:00'), todayEnd]);
        props.setSelectedRange('week');
        break;
      case 'all':
        props.setDates(null);
        props.setSelectedRange('all');
        break;
      case 'custom':
        props.setDates(customDate);
        props.setSelectedRange('custom');
        break;
      default:
        new Error('Incorrect date preset.');
    }
    setDatePreset(newPreset);
  };

  return (
    <>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="date-selector">{t('Table.date')}</InputLabel>
        <Select
          labelId="date-selector"
          id="date-selector-id"
          value={datePreset}
          onChange={(e) => handleDatePreset(e.target.value)}
          label="Date"
          classes={{
            icon: classes.selectIcon,
          }}
          SelectDisplayProps={{
            style: {
              padding: '10px 10px 10px 6px',
              fontSize: '12px',
              textOverflow: 'ellipsis',
            },
          }}
        >
          <MenuItem className={classes.menu_item_dates} value={'day'}>
            {t('DatePicker.day')}
          </MenuItem>
          <MenuItem className={classes.menu_item_dates} value={'week'}>
            {t('DatePicker.week')}
          </MenuItem>
          <MenuItem className={classes.menu_item_dates} value={'all'}>
            {t('DatePicker.all')}
          </MenuItem>
          <MenuItem className={classes.menu_item_dates} value={'custom'}>
            {t('DatePicker.custom')}
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default DatePicker;
