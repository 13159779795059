import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../../../../store/index';
import { Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Projection from './Projection';
import { fetchPut } from '../../../../../helpers/fetching';
import {
  setSnackbar,
  fetchResults,
  setPostSurgery,
  setQualityOverlaysToShow,
} from '../../../../../store';
import ExaminationActions from './ExaminationActions/ExaminationActions';
import circleCoursor from '../../../../../icons/circle.png';
import CollectionsIcon from '@material-ui/icons/Collections';
import {
  breastVolumeChip,
  postOpChip,
  reductionScarsChip,
} from './Common/ProjectionCommon';
import { useAppliedEvaluation } from '../../../../../helpers/hooks/useEvaluation';

const useStyles = makeStyles((theme) => ({
  projection_card_container: {
    // minWidth: 'min(200px, 100%)',
    display: 'flex',
    // flexDirection: 'column',
    width: '100%',
    justifyContent: 'inherit',
    backgroundColor: 'black',
    borderRadius: '5px',
    minHeight: '10vh',
  },

  titleLeft: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(2),
  },
  titleRight: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(2),
  },

  secondCaptureIconLeft: {
    position: 'absolute',
    top: theme.spacing(1),
    right: '20%',
    color: '#d3d3d3',
    cursor: 'pointer',
    zIndex: 9,
    '& .MuiBadge-badge': {
      right: -3,
      top: 13,
      padding: '0 2px',
    },
  },

  secondCaptureIconRight: {
    position: 'absolute',
    top: theme.spacing(1),
    left: '20%',
    color: '#d3d3d3',
    cursor: 'pointer',
    zIndex: 9,
    '& .MuiBadge-badge': {
      right: -3,
      top: 13,
      padding: '0 2px',
    },
  },

  clipsPatchIconRight: {
    position: 'absolute',
    top: '26%',
    left: theme.spacing(1),
    color: '#2e74ca',
    zIndex: 9,
    height: '25px',
    width: '25px',
    minHeight: '25px',
    cursor: 'help',
    '&:hover': {
      backgroundColor: '#232323',
    },
  },

  clipsPatchIconLeft: {
    position: 'absolute',
    top: '26%',
    right: theme.spacing(1),
    color: '#2e74ca',
    zIndex: 9,
    height: '25px',
    width: '25px',
    minHeight: '25px',
    cursor: 'help',
    '&:hover': {
      backgroundColor: '#232323',
    },
  },

  postOpLeft: {
    position: 'absolute',
    top: '38%',
    right: theme.spacing(1),
    zIndex: 9,
    height: '25px',
    width: '25px',
    minHeight: '25px',
    cursor: 'help',
    '&:hover': {
      backgroundColor: '#232323',
    },
  },

  postOpRight: {
    position: 'absolute',
    top: '38%',
    left: theme.spacing(1),
    zIndex: 9,
    height: '25px',
    width: '25px',
    minHeight: '25px',
    cursor: 'help',
    '&:hover': {
      backgroundColor: '#232323',
    },
  },

  compression_right: {
    position: 'absolute',
    top: '14%',
    left: theme.spacing(1),
    zIndex: 9,
    height: '25px',
    width: '25px',
    minHeight: '25px',
    cursor: 'help',
  },

  compression_left: {
    position: 'absolute',
    top: '14%',
    right: theme.spacing(1),
    height: '25px',
    width: '25px',
    minHeight: '25px',
    zIndex: 9,
    cursor: 'help',
  },

  tooltip_text: {
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    fontSize: '12px',
  },
}));

const ProjectionsCard = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Store state
  const results = useSelector((state) => state.results);
  const {
    projections,
    applied_quality,
    applied_density,
    shown_quality,
    shown_density,
    projections: projectionData,
  } = results;
  const { qualityOverlaysToShow } = useSelector((state) => state.images);
  const { tabsIndex, zoomedProjection } = useSelector((state) => state.gui);
  const {
    qualityInRevisionMode,
    qualityInDrawingMode,
    densityInRevisionMode,
    diagnosticsInRevisionMode,
    customOpacities,
    customMicrocalc,
  } = useSelector((state) => state.labeling);
  const currentExam = useSelector((state) => state.examinations.currentExam);

  // Local state
  const [fetchingEval, setFetchingEval] = useState(false);
  const [cursor, setCursor] = useState('default');
  const [volumeTooltipOpen, setVolumeTooltipOpen] = useState(false);
  const [volumeChipClicked, setVolumeChipClicked] = useState(false);
  // const [noTypeRectangles, setNoTypeRectangles] = useState([]);

  const inRevisionQuality =
    (tabsIndex === 1 && qualityInRevisionMode) ||
    (tabsIndex === 1 && qualityInDrawingMode);

  // Variables
  const qualityShown = shown_quality || applied_quality;
  const densityShown = shown_density || applied_density;

  const resultQualityEvaluation = useAppliedEvaluation(
    'quality',
    qualityShown,
    qualityInRevisionMode
  );

  const resultDensityEvaluation = useAppliedEvaluation(
    'density',
    densityShown,
    densityInRevisionMode
  );

  // Breast area per projection
  const calculateBreastArea = (proj) => {
    const grossBreastArea =
      resultQualityEvaluation?.[proj]?.GrossBreastArea || 0;
    const pectoArea = resultQualityEvaluation?.[proj]?.PectoArea || 0;
    const thickness = Number(projections?.[proj]?.BodyPartThickness) || 0;
    return ((grossBreastArea - pectoArea) * thickness) / 1000;
  };

  // Current Breast Volume
  const bodyPartThickness =
    results.projections?.[props.proj_name]?.BodyPartThickness;
  const pastBreastVolume = projectionData.rcc?.pastBreastVolumes;
  const currentBreastTissueDepicted = calculateBreastArea(props.proj_name);
  const volumeProjectionDiff =
    resultQualityEvaluation?.[props.proj_name]?.VolumeProjectionDiff;

  const breastAreas = {
    rmlo: calculateBreastArea('rmlo'),
    lmlo: calculateBreastArea('lmlo'),
    rcc: calculateBreastArea('rcc'),
    lcc: calculateBreastArea('lcc'),
  };

  const mloBreastArea =
    props.proj_name === 'rcc' ? breastAreas.rmlo : breastAreas.lmlo;
  const ccBreastArea =
    props.proj_name === 'rmlo' ? breastAreas.rcc : breastAreas.lcc;

  const breastAreaDifference = (
    currentBreastTissueDepicted -
    (props.proj_name === 'rcc' || props.proj_name === 'lcc'
      ? mloBreastArea
      : ccBreastArea)
  ).toFixed(0);

  const noPastBreastEvaluations =
    !pastBreastVolume ||
    (Array.isArray(pastBreastVolume) && pastBreastVolume.length === 0);

  const volumeLastExamDiff =
    resultQualityEvaluation?.[props.proj_name]?.VolumeLastExamDiff;

  useEffect(() => {
    if (qualityInDrawingMode) setCursor(`url(${circleCoursor}) 10 10, auto`);
    else if (diagnosticsInRevisionMode) setCursor('crosshair');
    else setCursor('default');
  }, [tabsIndex, qualityInDrawingMode, diagnosticsInRevisionMode]);

  // Handlers
  const setEvaluated = async (state) => {
    setFetchingEval(true);
    const response = await fetchPut(
      'evaluate',
      {
        projection_id: projections[props.proj_name]?.uid,
        state,
      },
      'bbox'
    );

    if (response.success) {
      dispatch(fetchResults());
      setFetchingEval(false);
    } else {
      dispatch(
        setSnackbar({
          msg: response.msg,
          severity: 'error',
        })
      );
      setFetchingEval(false);
    }
  };

  const showClips = (currentProj) => {
    const isClipsVisible =
      qualityOverlaysToShow[`clips${currentProj.toUpperCase()}`];
    const newLesionsToShow = {
      [`clips${currentProj.toUpperCase()}`]: !isClipsVisible,
    };
    !inRevisionQuality && dispatch(setQualityOverlaysToShow(newLesionsToShow));
  };

  const postopHandler = (operation) => {
    let newEvaluationState = [
      ...resultQualityEvaluation?.[props.proj_name]?.PostSurgery,
    ];
    if (operation.includes('reductionScars')) {
      newEvaluationState = newEvaluationState.filter(
        (item) => item !== 'reductionScars'
      );
    } else {
      newEvaluationState.push('reductionScars');
    }
    dispatch(setPostSurgery(currentExam, props.proj_name, newEvaluationState));
  };

  return (
    <div
      className={classes.projection_card_container}
      style={{ cursor: cursor }}
    >
      {/* Second captures */}
      {!!props.onCaptureClick && (
        <Tooltip
          arrow
          title={
            <Typography className={classes.tooltip_text}>
              {t('ResultView.secondCaptures')}
            </Typography>
          }
          placement="bottom-end"
        >
          <CollectionsIcon
            className={
              props.left
                ? classes.secondCaptureIconLeft
                : classes.secondCaptureIconRight
            }
            fontSize="medium"
            onClick={props?.onCaptureClick}
          />
        </Tooltip>
      )}
      {/* Compression  */}
      {resultQualityEvaluation?.[props.proj_name] &&
        currentBreastTissueDepicted !== 0 &&
        currentBreastTissueDepicted &&
        breastVolumeChip(
          currentBreastTissueDepicted,
          volumeProjectionDiff,
          breastAreaDifference,
          noPastBreastEvaluations,
          pastBreastVolume,
          volumeLastExamDiff,
          volumeTooltipOpen,
          setVolumeTooltipOpen,
          volumeChipClicked,
          setVolumeChipClicked,
          props.proj_name,
          props.left,
          theme,
          classes,
          t,
          dispatch,
          actions
        )}
      {/* POSTOP Clips  */}
      {(tabsIndex === 0 || tabsIndex === 1) &&
        resultQualityEvaluation?.[props.proj_name] &&
        !qualityInRevisionMode &&
        postOpChip(
          resultQualityEvaluation?.[props.proj_name]?.clips,
          showClips,
          qualityOverlaysToShow,
          props.proj_name,
          props.left,
          classes,
          theme,
          t
        )}
      {/* POSTOP Reduction Scars */}
      {(tabsIndex === 0 || tabsIndex === 1) &&
        resultQualityEvaluation?.[props.proj_name] &&
        !qualityInRevisionMode &&
        reductionScarsChip(
          resultQualityEvaluation?.[props.proj_name],
          props.left,
          classes,
          postopHandler,
          theme,
          t
        )}
      {/* Projection Actions  */}
      {
        <ExaminationActions
          proj_name={props.proj_name}
          src={props.src}
          qualityEvaluation={resultQualityEvaluation}
          densityEvaluation={resultDensityEvaluation}
          setEvaluated={setEvaluated}
          fetchingEval={fetchingEval}
          projection={projections?.[props.proj_name]}
        />
      }
      <Projection
        proj_name={props.proj_name}
        onCaptureClick={props.onCaptureClick}
        onLoad={props.onLoad}
        uid={props?.uid}
        evaluation={resultQualityEvaluation?.[props?.proj_name]}
        setEvaluated={setEvaluated}
        left={props.left}
        src={props.src}
        fetchingEval={fetchingEval}
        projection={projections?.[props?.proj_name]}
        customOpacities={customOpacities?.[props?.proj_name]}
        customMicrocalc={customMicrocalc?.[props?.proj_name]}
      />
    </div>
  );
};

export default ProjectionsCard;
