import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DraggableDialog from '../DraggableDialog/DraggableDialog';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import * as actions from '../../../../store/index';
import { Grid, Tab, Tabs } from '@material-ui/core';
import TabPanel from '@material-ui/lab/TabPanel';
import UsersPanel from './UsersPanel';
import UsersStats from './UsersStats';
import RegisterDialog from './RegisterUsers/RegisterDialog';

const useStyles = makeStyles((theme) => ({
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    letterSpacing: '0.1rem',
    textTransform: 'uppercase',
    color: theme.palette.secondary.main,
    alignItems: 'center',
  },
}));

function UserManagement(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const notifications = useSelector((state) => state.gui.notifications);

  const [value, setValue] = useState(0);
  const [newRow, setNewRow] = useState(false);

  const closeUsersDialog = () => {
    dispatch(actions.setActiveDialog(null));
    dispatch(actions.setNotifications([]));

    notifications.forEach((notification) => {
      dispatch(actions.deleteNotification(notification.id));
    });
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    );
  }

  return (
    <DraggableDialog
      maxWidth="xl"
      title={t('users.title')}
      open={props.open}
      actions={[
        {
          onClick: closeUsersDialog,
          color: 'primary',
          label: t('Console.close'),
        },
      ]}
      onClose={closeUsersDialog}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-start"
        spacing={1}
        style={{ minHeight: '30vh' }}
      >
        <Grid item xs={1}>
          <Tabs
            className={classes.tabs}
            orientation="vertical"
            value={value}
            onChange={(_, newVal) => setValue(newVal)}
          >
            <Tab
              label={t('users.title')}
              id="filters"
              style={{ alignSelf: 'center', padding: 0 }}
            />
            <Tab
              label={t('users.user_stats')}
              id="report"
              style={{ alignSelf: 'center', padding: 0 }}
            />
          </Tabs>
        </Grid>
        <Grid item xs={11}>
          <TabPanel value={value} index={0}>
            <UsersPanel newRow={newRow} setNewRow={setNewRow} />
          </TabPanel>

          <TabPanel value={value} index={1}>
            <UsersStats />
          </TabPanel>
        </Grid>
      </Grid>
      <RegisterDialog newRow={newRow} setNewRow={setNewRow} />
    </DraggableDialog>
  );
}

export default UserManagement;
